.dimmed.dimmable > .ui.modals.dimmer.visible {
  display: flex !important;
}

.map {
  height: 500px;
}
.cards {
  height: 400px;
}
.indexcard {
  width: 100px;
  height: 50px;
  text-align: center;
  background-color: #d9d9d9 !important;
  box-shadow: 20px 20px 60px #d9d9d9;
}

.nav-tabs .nav-link {
  margin-right: 8px;
  border-bottom: 3px solid #000000;
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  text-align: center;
  color: #f5d76e !important;
}

.loaction .nav-link {
  padding: 0% !important;
}

.nav-tabs {
  border-bottom: none !important;
}
@media only screen and (min-width: 1024px) {
  #viewDiv {
    padding: 0;
    margin: 0;
    height: 100vh !important;
    width: 100%;
  }
}

.cont {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 10px;
}
.cont .card {
  width: 150px !important;
  border: 0px solid grey;
  height: 150px !important;
  transform-style: preserve-3d;
  position: relative;
  transition: all 1s, box-shadow 2.5s;

  animation: fade-in-bottom 1.5s;
  animation-fill-mode: backwards;
}
.cont .card:hover {
  transform: rotatey(-180deg) translatey(-20px);
}
.cont .card .front {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  backface-visibility: hidden;
  border-radius: 15px;

  z-index: 99999;
  overflow: hidden;
}
.cont .card .front .img-cont {
  width: 100%;
  height: 90px;
  overflow: hidden;
}
.cont .card .front .img-cont img {
  margin-top: 20%;
  margin-left: 32%;
  width: 50px;
  height: 50px;
}
.cont .card .front .content-cont {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 10px;
  font-family: monospace;
}
.cont .card .back {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  backface-visibility: hidden;
  border-radius: 15px;
  background-color: white;
  transform: rotatey(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: monospace;
}
.cont .card button {
  padding: 10px 20px;
  background-color: #14274e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}
.cont .card button::after {
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: "";
  background-color: #14274e;
  z-index: -1;
  transition: 0.8s;
}
.cont .card button:hover {
  background-color: #0a1325;
}
.cont .card button:hover::after {
  transform: scale(1.5);
  opacity: 0;
}
@keyframes fade-in-bottom {
  0% {
    transform: translatey(50px);
  }
  100% {
    transform: translatey(0);
  }
}

.ui.menu .item:before {
  width: 0px !important;
}
.portfolio {
  width: 190%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
#form-input-control-portfolio {
  border: 1px solid #2e2e38 !important;
  background-color: #ffffff !important;
}
label[for="form-input-control-portfolio"] {
  color: #2e2e38 !important;
  font-size: 14px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f5d76e !important;
  border-color: #f5d76e !important;
}
.asset {
  box-shadow: 0px !important;
  font-size: 20px !important;
  padding: 10px !important;
}
.asset .card {
  border-radius: 5% !important;
  background-image: url("../assets/card.png");
  background-size: 100% !important;
  height: 150px !important;
}
.asset .card-body {
  color: black !important;
}
.card-img {
  height: auto !important;
}
.card-body h6 {
  color: black !important;
}
.asset tr {
  height: 3vh !important;
}
.divider.text {
  color: grey !important;
}

.ui.modal > .close {
  color: #ffffff !important;
}

.ui.modal > .header:not(.ui) {
  color: #ffffff !important;
}

.drodown.icon {
  font-size: 20px !important;
  margin-top: -20px !important;
}
a[value="Flood"] > i,
a[value="Typhoon"] > i,
a[value="Storm Surge"] > i {
  display: none !important;
}

aside div button {
  box-shadow: none !important;
}
.location .nav-link {
  font-size: 13px !important;
  background-color: #ffffff !important;
  padding: 1% !important;
  color: black !important;
  padding-top: 1% !important;
  border-bottom: 3px solid #f5d76e !important;
}
.location .nav-link.active {
  background-color: #f5d76e !important;
  color: white !important;
  border-bottom: 3px solid #f5d76e !important;
  box-shadow: none;
}
.csvbox h3 {
  text-align: center !important;
  color: #f5d76e !important;
}
.csvbox .border {
  height: 500px !important;
  overflow-y: auto;
  border: 2px solid #f5d76e !important;
}
.border .ui.label {
  margin-bottom: 5% !important;
  margin-top: 1% !important;
  margin-left: 6.5% !important;
  margin-right: 6.5% !important;

  font-weight: 510 !important;
}
#video {
  width: 100% !important;
  height: 60% !important;
  padding: 5% !important;
  box-shadow: inset 2px 2px 5px #d1d9e6, inset -3px -3px 7px #f9f9f9 !important;
}
.ant-checkbox-wrapper span {
  color: black !important;
}
.ant-checkbox-wrapper-disabled span {
  color: grey !important;
}

.inputGroup {
  background-color: #fff;
  display: block;
  margin: 15px 15px;
  position: relative;
  padding: 20px;
  box-shadow: 11px 11px 22px #ebebeb, -11px -11px 22px #ffffff;
  border-radius: 20px;
}
.inputGroup label {
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 2px !important;
}
.inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.inputGroup label:after {
  width: 20px;
  height: 20px;
  content: "";
  border: 2px solid #d1d7dc;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 2px 3px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
}
.inputGroup input:checked ~ label {
  color: #fff;
}
.inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.inputGroup input:checked ~ label:after {
  background-color: #f5d76e;
  border-color: #f5d76e;
}
.inputGroup img {
  width: 150px !important;
  height: 70px !important;
}
.inputGroup input {
  width: 100px;
  height: 100px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}
.form {
  padding: 0 16px;

  margin: 50px auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
}
code {
  background-color: #9aa3ac;
  padding: 0 8px;
}
.asset .table .table__heading {
  background-color: white !important;
  color: black !important;
}
.asset {
  overflow-y: auto !important;
  box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff !important;
  padding: 20px !important;
  border-left: 10px solid white !important;
  border-right: 10px solid white !important;
  /*background-image: url("../assets/map.png");*/
  background-color: rgba(255, 255, 255, 0.5) !important;
  scrollbar-face-color: #f5d76e !important;
}
.asset::-webkit-scrollbar-thumb {
  background: #f5d76e;
}
.asset tr:first-child {
  width: 60% !important;
}

.asset .card {
  font-size: 15px !important;

  border: none !important;
  color: black !important;
}
.asset th:first-child {
  width: 50% !important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: white !important;
  color: #f5d76e !important;
}

.fields {
  width: 90%;
  margin-left: 4% !important;
  margin-top: 2% !important;
}

.transition-label {
  color: #000000 !important;
  font-size: 15px !important;
}

.transition-ip {
  background-color: transparent !important;
  box-shadow: 5px 5px 6px #dbdbdb, -5px -5px 6px #ffffff !important;
  width: 40% !important;
}

.transition-ip-nav {
  background-color: transparent !important;
  box-shadow: 5px 5px 6px #dbdbdb, -5px -5px 6px #ffffff !important;
  width: 13% !important;
}

.no-project {
  animation-duration: 1.3s;
  animation-name: slidein;
  margin-left: 9%;
  margin-top: 10%;
}

@keyframes slidein {
  from {
    margin-left: 0%;
  }

  to {
    margin-left: 9%;
  }
}

.projectpara1 {
  font-size: 50px;
  font-family: "Volkhov", serif;
  color: #f5d76e;
  position: relative;
}

.errorpng {
  position: absolute;
  margin-left: 40%;
  margin-top: -18%;
  width: 20%;
}
.transition table {
  margin-left: 0% !important;
}
.transition .ant-table th {
  background-color: grey !important;
  color: black !important;
  font-family: "Montserrat", sans-serif;
}

.sbtitable .ui.selection.dropdown {
  border: 0.5px solid black !important;
}

.climate_brain_navbar {
  background-color: #f5d76e !important;
  color: white !important;
  border-radius: 0px !important;
}
.climate_brain_navbar a {
  color: white !important;
  font-weight: bold !important;
}
table {
  margin-left: 0% !important;
}

.cbrainmap {
  padding: 0% !important;
}

.esri-widget--button {
  background-color: #f5d76e !important;
  color: white !important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
:focus {
  outline: 0px;
}
.variable_title {
  font-size: 30px;
  font-weight: 700;
  color: #292d3f;
  text-align: center;
  margin-bottom: 50px;
}

.variable_card_area {
  position: relative;
  margin-bottom: 30px;
}
.single_variable_card {
  border: 1px solid #efefef;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -khtml-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.variable_card_title {
  padding: 5px;
  text-align: center;
  background-color: #d6d6d6;
}
.variable_card_title h3 {
  font-size: 14px;
  font-weight: 400;
  color: #292d3f;
  margin-bottom: 0;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -khtml-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.variable_card_title h3 i {
  opacity: 0;
}
.variable_card_icon {
  max-width: 100%;
  min-height: 85px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.variable_icon {
  width: 40px;
  height: 45px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -khtml-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.variable_icon1 {
  background-image: url("../assets/flood.png");
}
.variable_icon2 {
  background-image: url("../assets/typhoon.png");
}
.variable_icon3 {
  background-image: url("../assets/storm.png");
}
.variable_icon5 {
  background-image: url("../assets/landslide.png");
}
.variable_icon6 {
  background-image: url("../assets/rainfall.png");
}
.variable_icon7 {
  background-image: url("../assets/heat.png");
}
.variable_icon8 {
  background-image: url("../assets/snow.png");
}
.variable_icon9 {
  background-image: url("../assets/sea.png");
}
.variable_icon4 {
  background-image: url("../assets/wildfire.png");
}
.variable_checkbox {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: pointer;
}
.ant-checkbox-wrapper-checked ~ .single_variable_card {
  border: 1px solid #2e2e38 !important;
}
.ant-checkbox-wrapper-checked:hover ~ .single_variable_card {
  border: 1px solid #2e2e38 !important;
}

.ant-checkbox-wrapper-checked
  ~ .single_variable_card
  .variable_card_content
  .variable_card_title {
  background-color: #f5d76e !important;
  color: #2e2e38 !important;
}
.ant-checkbox-wrapper-checked
  ~ .single_variable_card
  .variable_card_content
  .variable_card_title
  h3 {
  color: #2e2e38 !important;
}
.ant-checkbox-wrapper-checked
  ~ .single_variable_card
  .variable_card_content
  .variable_card_title
  h3
  i {
  opacity: 1 !important;
}
.ant-checkbox-wrapper-checked:hover ~ .variable_card_title {
  border: 1px solid #2e2e38 !important;
}

/*Icon Selector*/

.ant-checkbox-wrapper-checked
  ~ .single_variable_card
  .variable_card_content
  .variable_card_icon {
  color: #f5d76e !important;
}

.variable_card_icon {
  font-size: 50px;
  color: #000000;
}

.ant-checkbox-group {
  width: 100% !important;
}

.ant-checkbox-wrapper-disabled ~ .single_variable_card {
  border: 1px solid #f5d76e !important;
}
.ant-checkbox-wrapper-disabled:hover ~ .single_variable_card {
  border: 1px solid #f5d76e !important;
}

.ant-checkbox-wrapper-disabled
  ~ .single_variable_card
  .variable_card_content
  .variable_card_title {
  background-color: #f5d76e !important;
  color: #2e2e38 !important;
}
.ant-checkbox-wrapper-disabled
  ~ .single_variable_card
  .variable_card_content
  .variable_card_title
  h3 {
  color: #2e2e38 !important;
}
.ant-checkbox-wrapper-disabled
  ~ .single_variable_card
  .variable_card_content
  .variable_card_title
  h3
  i {
  opacity: 1 !important;
}
.ant-checkbox-wrapper-disabled:hover ~ .variable_card_title {
  border: 1px solid #f5d76e !important;
}

/*Icon Selector*/

.ant-checkbox-wrapper-disabled
  ~ .single_variable_card
  .variable_card_content
  .variable_card_icon {
  color: #f5d76e !important;
}

.heading_2 {
  padding-top: 0.5% !important;
  margin: 0 !important;
  color: #f5d76e !important;
  font-family: "Source Sans Pro" !important;
  letter-spacing: 2px !important;
}
.para_2 {
  font-family: "Source Sans Pro" !important;
  letter-spacing: 1px !important;
  font-size: 12px !important;
}
.heading_column {
  padding: 0 !important;
}
.heading_row {
  background-color: #2e2e38 !important;
  color: white !important;
  padding: 0 !important;
}
.ant-table-row:nth-child(even) {
  background-color: #f5d76e !important;
}
.ant-table-row:nth-child(odd) {
  background-color: white !important;
}
.location-map-container .mapboxgl-marker {
  background-size: cover;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;

  cursor: pointer;
}
.assest .ant-table-wrapper {
  width: 100% !important;
}
