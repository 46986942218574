.loader {
  width: 120px;
  height: 80px;
  
  top: 0; right: 0; left: 0; bottom: 0;
  margin: auto;
}
.loader .image {
  width: 50px;
  height: 50px;
  font-size: 40px;
  margin-left:20px;
  text-align: center;
  transform-origin: bottom center;
  animation: 3s rotate infinite;
  opacity: 0;
}
.loader p{
  margin:0;
  margin-top:10px;
}
.loader span {
  display: block;
  width: 100%;
  text-align: center;
  color:black;
  top:10;
  bottom: 0;
  
}

@keyframes rotate{
  0% {
    transform: rotate(90deg);
  }
  10% {
    opacity: 0;
  }
  35% {
    transform: rotate(0deg);
    opacity: 1;
  }
  65% {
    transform: rotate(0deg);
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    transform: rotate(-90deg);
  }
}
.map-container {
position: absolute;
margin-top:9.5%;
right: 0;
margin-left:28%;

padding:10%;
bottom: 0;
width:70%;
height:60%;
}