body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Montserrat !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
span,
button,
a {
  font-family: Montserrat !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error-card {
  position: absolute;
  margin-left: 38%;
  margin-top: 7 !important;
  margin-top: -90%;
  width: 28rem;
}
.error-card-body {
  background-color: white;
  padding: 20%;
  border-radius: 5px;
}
.error-card-main-text {
  font-size: 55px;
  font-family: "Montserrat", sans-serif;
  color: grey;
  font-weight: bold;
  text-align: center;
  padding-top: 5%;
}
.error-card-title {
  color: #5675ea;
  text-align: center;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
}
.error-card-text {
  font-size: 14px;
  text-align: center;
}

.errorbg {
  width: 100% !important;

  position: relative;
}
