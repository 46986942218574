.card {
  border: 0.5% solid rgb(243, 247, 250, 0.05) !important;
  border-radius: 1%;
  padding: 2%;
  margin-left: 10%;
}

.topbar {
  overflow: hidden;
  background-color: transparent !important;
  margin-top: 5%;
  margin-left: 2% !important;
}

.topbar a {
  float: left;
  color: #111;
  text-align: center;

  text-decoration: none;
  font-size: 1.2rem;
}

.topbar a:hover {
  border-bottom: 3px solid #6a2846;
  color: black;
}

.topbar a.active {
  background-color: #6a2846;
  border-bottom: 3px solid #6a2846;
  color: white;
}

#riskindices table {
  width: 50%;
  margin-left: 15%;
}
.riskindices1 table {
  margin-left: 0% !important;
}

#riskindices h1 {
  font-family: "Raleway", sans-serif;
  color: #6a2846;
  margin-bottom: 2%;
}

#riskindices th {
  padding: 1.8%;
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-size: 14px;
  background-color: #6a2846;
  border: 2px solid #6a2846;
}

#riskindices td {
  border: 1px solid #6a2846;
  padding: 10px;
}

#t01 tr:nth-child(odd) {
  background-color: #ebf0fa;
}
#t01 tr:nth-child(even) {
  background-color: #fff;
}

.riskindices1 table {
  width: 100%;
}

.riskindices1 td {
  border: 1px solid #6a2846;
  padding: 10px;
}

.nav-link.active {
  background-color: #6a2846 !important;
  color: white !important;
}

.Datasets .nav-link {
  font-size: 17px;
  color: black;
}

ul.a {
  list-style-type: disc;
  margin-left: 17px;
}

.a_ol {
  list-style-type: circle;
  margin-left: 17px;
}

#datasets ul {
  font-size: 16px;
}

#datasets table {
  width: 90%;
  margin-left: 5%;
}

#datasets h1 {
  font-family: "Raleway", sans-serif;
  color: #6a2846;
  margin-bottom: 2%;
}

#datasets th {
  padding: 1.8%;
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-size: 14px;
  background-color: #6a2846;
  border: 2px solid #6a2846;
}

#datasets td {
  border: 1px solid #6a2846;
  padding: 10px;
  text-align: center;
}

#ct table {
  width: 90%;
  margin-left: 5%;
}

#ct h1 {
  font-family: "Raleway", sans-serif;
  color: #6a2846;
  margin-bottom: 2%;
}

#ct th {
  padding: 1.8%;
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-size: 14px;
  background-color: #6a2846;
  border: 2px solid #6a2846;
}

#ct td {
  border: 1px solid #6a2846;
  padding: 10px;
  text-align: center;
}

#container {
  position: relative;
}

#child {
  position: absolute;
  top: 45%;
  left: 10px;
  padding: 5px;
}

#container1 {
  position: relative;
}

#child1 {
  position: absolute;
  top: 10%;
  left: 10px;
  padding: 5px;
}

#t04 {
  margin-right: 5% !important;
}

#t04 tr:nth-child(odd) {
  background-color: #ebf0fa;
}
#t04 tr:nth-child(even) {
  background-color: #fff;
}

#t03 {
  margin-right: 5% !important;
}

#t03 tr:nth-child(odd) {
  background-color: #ebf0fa;
}
#t03 tr:nth-child(even) {
  background-color: #fff;
}

/* #t01 table{
  width: 90% !important;
  margin-left: 5%;
} 

#t01 th{
    padding: 1.8% !important;
    text-align: center !important;
    font-family: 'Roboto', sans-serif !important;
    color: #fff !important;
    font-size: 14px !important;
    background-color: #6a2846 !important;
    border: 2px solid #6a2846 !important;
}

#t01 td{
  border: 1px solid #6a2846 !important;
  padding: 10px !important;
  text-align: center !important;
} */

#formula {
  border: none !important;
  box-shadow: 11px 11px 22px #ededed, -11px -11px 22px #ffffff;
  border-radius: 20px !important;
  background-color: #ffffff !important;
  width: 70% !important;
  padding: 0.5% !important;
  margin-bottom: 20px;
}

.nav-pills .nav-link {
  color: #6a2846 !important;
  margin-bottom: 10px !important;
}

.met_parent > .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
}

.nav-link.active {
  background-color: transparent !important;
  color: #6a2846 !important;
  border-left: 3px solid #6a2846;
}

.rfdiv {
  position: relative;
  margin-top: 30%;
}
