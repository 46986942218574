.rowbtn{
	background-color: rgb(255,171,0) !important;
	border: none !important;
	width: 120px;
	height: 40px !important;
	border-radius: 5px !important;
}

.ant-table.ant-table-bordered > .ant-table-container{
	border: none !important;
}


.transitiontable .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th{
	background-color: #002060 !important;
	color: white !important;
	border-color: #002060 !important;
	font-family: 'Montserrat', sans-serif;;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
	border: none !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
	background-color: #ffffff !important;
}

.transitiontable .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:nth-child(even){
	background-color: #002060 !important;

}

