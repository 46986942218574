.recharts-text {
  text-anchor: middle !important;
}

.mapbox {
  box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff !important;
  padding: 5rem !important;
}

.losshr {
  border-top: 1px solid rgb(164, 176, 190) !important;
}
.Filter .dropdown {
  margin-left: 1rem !important;

  width: 80% !important;
}
.Filter .dropdown > .dropdown.icon {
  right: -6em !important;
}
.Filter label {
  color: black !important;
  font-size: 16px !important;
  padding: 1rem !important;
}
.mapbox .shadow-inset {
  box-shadow: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff !important;
  padding: 1.5rem !important;
}

.heading {
  border-bottom: 1px solid #eee;
  min-height: 60px;
  line-height: 60px;
  padding: 0 10px;

  color: #fff;
}

.listings {
  height: 100%;
  overflow: auto;
  padding-bottom: 60px;
}

.listings .item {
  display: block;
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-decoration: none;
}

.listings .item:last-child {
  border-bottom: none;
}
.listings .item .title {
  display: block;
  color: #6a2846;
  font-weight: 500;
  font-size: 15px;
}

.listings .item .title small {
  font-weight: 400;
}
.listings .item.active .title,
.listings .item .title:hover {
  color: #6a2846;
  font-size: 15px;
}
.listings .item.active {
  background-color: #f8f8f8;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-left: 0;
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-track {
  background: none;
}
::-webkit-scrollbar-thumb {
  background: #00853e;
  border-radius: 0;
}

.marker {
  border: none;
  cursor: pointer;
  height: 56px;
  width: 56px;
  background-image: url(../assets/marker.png);
  background-color: rgba(0, 0, 0, 0);
}

.clearfix {
  display: block;
}
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/* Marker tweaks */
.mapboxgl-popup {
  padding-bottom: 0px;
}

.mapboxgl-popup-close-button {
  display: none;
}
.mapboxgl-popup-content {
  padding: 0;
}
.mapboxgl-popup-content-wrapper {
  padding: 1%;
}
.mapboxgl-popup-content h5 {
  background: #91c949;
  color: #fff;
  margin: 0;
  display: block;
  padding: 5px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
  margin-top: -15px;
}

.mapboxgl-popup-content h4 {
  color: white !important;
  margin: 0;
  display: block;
  padding: 10px 10px 10px 10px;
  font-weight: 400;
}

.mapboxgl-popup-content div {
  padding: 10px;
}

.mapboxgl-container .leaflet-marker-icon {
  cursor: pointer;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-content {
  margin-top: 15px;
}

.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
  border-bottom-color: #91c949;
}
