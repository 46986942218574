@media print and (color) {
  .invoice-logo {
    display: none !important;
  }
  .ant-layout-sider {
    display: none;
  }
  .tcfd-download{
    display:none;
  }
  .ui.top.fixed.menu {
    display: none;
  }
  .invoice-download {
    display: none !important;
  }
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .invoice {
    margin-left: -13% !important;
    padding-right: 10%%;
    margin-top: 13% !important;
    width: 30cm;
    height: 42cm;
    margin: 30mm 30mm 30mm 30mm;
  }
  .tcfd-page-break {
    page-break-before: always;
  }
}

.ui.table{
  border-collapse: collapse !important;
}