.nav-tabs{
	margin-top: 2px;
	margin-bottom: 10px !important;
}

.tcfd .tab-content, .tcfd .nav{
	margin-top: 1px !important;
  padding: 0.5%;
}

.tabstcfd > a {
	color:  #111;
	font-size: 16px;
  margin-left: 7px;
  margin-right: 7px;
  border: none;
 }
 
.tabstcfd > a :hover{
  border: none;
}


.tabstcfd{
  margin-bottom: 5px !important;
  margin-left: 15.3%;
}


.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
	margin-left: 20px;
}

hr {
	border-top: 1px solid black !important;
	max-width: 48% !important;
}

#tcfd-download-tab hr{
	margin-top: 1px !important;
	margin-bottom: 1px !important;
}

.tcfd p{
	font-size: 15px !important;
}

.header {
	font-weight: 600 !important;
	color: #111 !important;
}

.tcfd p{
	font-size: 16px;
}

@media print and (color) {
  .nav-tabs {
    display: none;
  }
  .ant-layout-sider {
    display: none;
  }
  .tcfd-download{
    display:none;
  }
  .ui.top.fixed.menu {
    display: none;
  }
  .tcfd-download {
    display: none;
  }
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .detailed-report {
    width: 21cm;
    height: 29.7cm;
    margin: 10mm 10mm 10mm 10mm;
  }
  .tcfd-page-break {
    page-break-before: always;
  }
}
.tcfdtable p{
  word-wrap: break-word;
}