input,
textarea {
  background-color: #ffffff !important;
  border: 1px solid #d3d4cf !important;

  margin: 4px 0;
  padding-left: 25px;
  font-size: 13px;
  letter-spacing: 0.15px;
  border: none;
  outline: none;
  font-family: "Montserrat", sans-serif;
  background-color: #ecf0f3;
  transition: 0.25s ease;
}
.ui.fluid.selection.dropdown {
  padding: 10px;
}
.divider.text {
  color: #f5d76e !important;
}
label {
  color: #0d0d0d !important;
}
.form-control {
  margin-bottom: 4% !important;
}
#form-input-control-project {
  border: 0px !important;
  background-color: rgb(242, 241, 239) !important;
  border-radius: ;
}

#form-input-control-project:hover {
  transform: scale3d(1.01, 1.2, 12);
}

.modal {
  height: auto;
  top: auto;
  left: auto;
  bottom: auto;
  right: auto;
}
.checkbox {
  width: 25%;
  padding: 5px;
}
.csv {
  background-color: #fdb827 !important;
  color: black !important;
  border: 1px solid white !important;
  font-size: 11px !important;
  opacity: 0.9;
  padding: 10px 15px 10px 15px !important;
}
.header {
  font-weight: 400 !important;
  padding-left: 20px !important;
  padding-right: 10px !important;
}
.ui.modal {
  background-color: #ffffff !important;
}
.ui.modal > .close {
  margin-right: 5%;
  top: 0rem !important;
  color: black !important;
  opacity: 1 !important;
  font-size: 2em !important;
}
.ui.fullscreen.scrolling.modal {
  left: 20px !important;
  right: 0px !important;
}
.ui.fullscreen.modal {
  width: 98% !important;
}

.table {
  max-width: 100%;
  width: 100%;
  padding-top: 2% !important;
  padding-bottom: 0.5% !important;
  padding-left: 0.5 !important;
  padding-right: 0.5% !important;

  border-radius: 0.55rem !important;
}

.table__row {
  display: flex;
  align-items: center;
  background-color: #ecf0f3;
  padding: 15px 0;
  transition: 0.2s ease;
  font-size: 1rem !important;
  color: grey;
  margin-bottom: 0.5%;
  box-shadow: 2px 2px 5px #d1d9e6;
  border-left: 4px solid #f5d76e;
  border-right: 4px solid #f5d76e;
}

@media screen and (max-width: 580px) {
  .table__row {
    flex-direction: column;
    justify-content: center;
  }
}
.table__row:hover {
  background-color: #f5f5f7;
  border-left: 6px solid #f5d76e;
  border-right: 6px solid #f5d76e;
}

.table__row:nth-child(odd):hover {
  background-color: #f5f5f7;
}
.table__heading {
  text-transform: capitalize;
  font-size: 14px !important;
  font-weight: bold;
  font-size: 1rem;
  padding: 10px;
  color: white;
  background-color: #f5d76e !important;
}
@media screen and (max-width: 580px) {
  .table__heading {
    display: none;
  }
}

.table__cell {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 780px) {
  .table__cell {
    justify-content: center;
    text-align: center;
    padding: 15px 0;
  }
}
.table__cell:first-child {
  width: 30%;
  padding-left: 20px;
}
@media screen and (max-width: 780px) {
  .table__cell:first-child {
    padding-left: 0;
    width: 100%;
  }
}
.table__cell:nth-child(2) {
  width: calc(30% / 2);
  margin-right: 10px;
}
@media screen and (max-width: 780px) {
  .table__cell:nth-child(2) {
    width: 100%;
  }
}

#table__cell_status {
  margin-right: 20px;
  margin-left: 0px !important;
}

.table__cell:nth-child(3) {
  width: calc(30% / 3);
  margin-left: 10px;
}
@media screen and (max-width: 780px) {
  .table__cell:nth-child(3) {
    width: 100%;
  }
}
.table__cell:nth-child(4) {
  width: calc(25% / 3) !important;
}
@media screen and (max-width: 780px) {
  .table__cell:nth-child(4) {
    width: 100%;
  }
}
.table__cell:nth-child(5) {
  width: 15%;
  text-align: left;
  justify-content: center;
  padding-right: 15px;
  margin-left: 29px;
}
.table__cell:nth-child(6) {
  width: 20%;
  text-align: left;
  justify-content: center;
  padding-right: 15px;
}
.table__cell:nth-child(7) {
  width: 15%;
  text-align: left;
  justify-content: center;
  padding-right: 15px;
}
.table__cell:nth-child(8) {
  width: 10%;
  text-align: left;
  justify-content: center;
  padding-right: 15px;
}
@media screen and (max-width: 580px) {
  .table__cell:nth-child(5) {
    padding-right: 0;
    width: 100%;
  }
}
.table__crypto-name {
  font-weight: bold;
  font-size: 14px;
  color: black;
}
.table__crypto-image {
  margin-right: 10px;
}
@media screen and (max-width: 580px) {
  .table__crypto-image {
    margin-right: 0;
  }
}
.button {
  border-radius: 4px;
  font-size: 12px;
  font-size: 1rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s ease;

  padding: 10px 10px;
}

.button--outline {
  border: 1px solid #556fe7;
  color: #fdb827;
}
.button--outline:hover {
  background-color: transparent;
  color: black;
}
.button--primary {
  color: #fdb827;
  background-color: transparent;
  border: 1px solid transparent;
  font-weight: 600;
}
.button--primary:hover {
  background-image: linear-gradient(to right, transparent, transparent);
  color: #fdb827;
  border: 1px solid black;
}
.buttons__ventar {
  border: 0.0625rem solid #f5d76e;
  background-color: transparent !important;
  color: #f5d76e;
  font-size: 10px;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

button:focus {
  outline: none;
}

.field,
.ui.input {
  width: 100% !important;
}

.tcfd .tab-content,
.tcfd .nav {
  margin-top: 3% !important;
}
.col-lg-4:nth-of-type(n) .card_eg_project {
  /*box-shadow: 20px 20px 60px #d9d9d9, -20px 20px 60px #ffffff;*/
  border-radius: 15px !important;
  margin-top: 3%;

  background-image: linear-gradient(to bottom, #fff, #fff, #fff);
  color: black !important;
  border: 1px solid #f5d76e;
}

.card_eg_top_2 {
  background: url("../assets/sidebar.png") !important;
  border-radius: 0px 45px 45px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  color: #f5d76e;
  background-size: 100% !important;
}

.card_eg .ui.progress .bar {
  background: #fdb827 !important;
}
.card_eg .ui.progress .bar {
  height: 0.5em !important;
}

.project_info {
  color: black !important;
  font-size: 20px !important;
  font-family: Montserrat !important;
  font-weight: bold !important;
}
.project_table {
  position: relative;
  top: 15%;
}
