#login a {
  color: #6a2846 !important;
}
#login {
  background: url("../assets/login_background.png") no-repeat center center
    fixed;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}
.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}
#login .row {
  background-color: white;
  box-shadow: 0px 20px 93px -4px rgba(107, 106, 106, 0.25);
  border-radius: 2.5% 2.5% 2.5% 2.5%;
}
#login_overlay {
  padding: 0% !important;
}
.login_button {
  background-color: #f5d76e !important;
  border: none !important;
  color: black !important;
  width: 100%;
}
.login_row {
  width: 100%;
}
