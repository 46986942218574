.sidebar .ant-layout-sider-children {
  background-color: #2e2e38 !important;
  height: 100vh !important;

  border-right: 1px solid #d3d3d3 !important;
}
.sidebar {
  position: fixed !important;
}
.sidebar .nav-item span {
  padding-right: 10% !important;
}
.sidebar .nav-item span svg {
  width: 1.75em !important;
  height: 1.75em !important ;
}
.sidebar .nav {
  display: flex !important;
  align-items: center !important;
  padding: 10% !important;
}
.sidebar .nav-item {
  color: white !important;
  display: flex !important;

  white-space: nowrap !important;
  padding: 5% !important;
}
.sidebar .nav-item .nav-link {
  color: white !important;
  padding-left: 5% !important;
  letter-spacing: 1px !important;
  font-family: "Source Sans Pro" !important;
  font-size: 14px !important;
}
#logo_sidebar {
  padding: 5% !important;
}
.sidebar ul {
  margin: 5% !important;
}
.sidebar ul svg {
  width: 1em !important;
  height: 1em !important;
}
.ant-menu-item-selected {
  background-color: #f5d76e !important;
  color: #2e2e38 !important;
}

.sidebar li a {
  font-size: 12px !important;
  color: white;
  letter-spacing: 1px !important;
  font-family: "Source Sans Pro" !important;
}
.sidebar li span {
  padding-right: 7.5% !important;
}
.sidebar .nav-link {
  width: 120px !important;
}
