@media print and (color) {
  .menu {
    display: none;
  }
  .ant-layout-sider {
    display: none;
  }
  .detailed-report-download {
    display: none;
  }
  .ui.top.fixed.menu {
    display: none;
  }
  .detailed-report-download {
    display: none;
  }
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .detailed-report {
    width: 29cm;
    height: 35cm;
    margin: 3mm 3mm 3mm 3mm;
  }
  .detailed-report-column {
    position: relative;
    z-index: 0;
    left: 0px;
    top: 0px;
  }
  .detailed-report-column {
    page-break-before: always;
  }
}

.detailed-report {
  /*border: 3px solid #002060;*/
  background-color: #fff;
  width: 55%;
  height: auto;
  margin: 3% auto;
  /* padding: 2% 3%; */
}

.detailed-report-download {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3%;
}

.detailed-report-page-break {
  /*border: 2px dashed #73a2ff;*/
  /* width: 100%;
  height: 15px; */
}

.detailed-report .detailed-report-title h1,
.detailed-report .detailed-report-title h2,
.detailed-report .detailed-report-title h3,
.detailed-report .detailed-report-title h4 {
  font-size: 1.1rem !important;
  padding: 0;
}

.detailed-report p {
  font-size: 0.9rem;
  line-height: 1.5;
}

.detailed-report .VictoryContainer svg text tspan {
  font-size: 12px;
}

.detailed-report-column {
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  padding: 10% 15%;
}

#detailed-report-cover {
  height: 29.7cm;
}

#detailed-report-cover > img {
  width: 100%;
  height: 100%;
}
#detail .row,
#detail .grid {
  display: block;
}

.detailed-report-navigation-column {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.detailed-report-navigation-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.33, #003),
    color-stop(0.67, #002060)
  );
}

.detailed-report-navigation-title > img {
  width: 5%;
  object-fit: contain;
  margin: 1% 2%;
}

.detailed-report-navigation-title > h2 {
  margin-top: 0;
  color: #fff;
}

.detailed-report-navigation-list {
  margin: 2%;
  line-height: 3;
}

.detailed-report-overall-column {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.detailed-report-overall-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.33, #003),
    color-stop(0.67, #002060)
  );
}

.detailed-report-overall-title > img {
  width: 5%;
  object-fit: contain;
  margin: 1% 2%;
}

.detailed-report-overall-title > h2 {
  margin-top: 0;
  color: #fff;
}

.detailed-report-overall-column .overallmap-container {
  margin: 4% 0;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.detailed-report-overall-map-container {
  margin: 2% auto;
  text-align: center;
}

.detailed-report-overall-map {
  margin: 2% 0;
}

.detailed-report-overall-information {
  display: flex;
  justify-content: center;
  text-align: justify;
  margin-top: 3%;
  flex-direction: column;
}

.detailed-report-overall-information-table-title {
  text-align: center;
  font-weight: bolder;
}

.detailed-report-overall-information-chart {
  margin-top: 10%;
  text-align: center;
  width: 100%;
}

.detailed-report-overall-information-chart-not-available {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.detailed-report-overall-information-chart-not-available > img {
  width: 25%;
  height: auto;
  object-fit: contain;
  margin: 1% auto;
}

.detailed-report-main-column {
  height: 29.7cm;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.detailed-report-main-title-header {
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-items: center;
}

.detailed-report-main-title-header > img {
  width: 15%;
  height: auto;
}

.detailed-report-main-column > h1 {
  display: inline-block;
  margin: 5% 2% 0;
  text-align: center;
  align-self: center;
}

.detailed-report-main-column > h2 {
  margin-top: 0.5%;
  align-self: center;
}

.detailed-report-main-map {
  margin: 5%;
}

.detailed-report-main-details {
  display: flex;
  justify-content: space-around;
}

.detailed-report-main-asset-details {
  width: 70%;
}

.detailed-report-main-variables-details {
  margin-left: 1%;
}

.detailed-report-storm-surge-column {
  height: 29.7cm;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.detailed-report-storm-surge-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.33, #003),
    color-stop(0.67, #002060)
  );
}

.detailed-report-storm-surge-title > img {
  width: 5%;
  object-fit: contain;
  margin: 1% 2%;
}

.detailed-report-storm-surge-title > h2 {
  margin-top: 0;
  color: #fff;
}

.detailed-report-storm-surge-description {
  text-align: justify;
  margin-top: 3%;
  margin-bottom: 2%;
}

.detailed-report-storm-surge-information {
  display: flex;
  justify-content: center;
  text-align: justify;
  margin-top: 3%;
}

.detailed-report-storm-surge-information-chart {
  width: 50%;
  padding: 1%;
  text-align: center;
}

.detailed-report-storm-surge-information-chart-not-available {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.detailed-report-storm-surge-information-chart-not-available > img {
  width: 25%;
  height: auto;
  object-fit: contain;
  margin: 1% auto;
}

.detailed-report-windspeed-column {
  height: 29.7cm;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.detailed-report-windspeed-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.33, #003),
    color-stop(0.67, #002060)
  );
}

.detailed-report-windspeed-title > img {
  width: 5%;
  object-fit: contain;
  margin: 1% 2%;
}

.detailed-report-windspeed-title > h2 {
  margin-top: 0;
  color: #fff;
}

.detailed-report-windspeed-information {
  text-align: justify;
  margin-top: 3%;
}

.detailed-report-typhoon-column {
  height: 29.7cm;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.detailed-report-typhoon-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.33, #003),
    color-stop(0.67, #002060)
  );
}

.detailed-report-typhoon-title > img {
  width: 5%;
  object-fit: contain;
  margin: 1% 2%;
}

.detailed-report-typhoon-title > h2 {
  margin-top: 0;
  color: #fff;
}

.detailed-report-typhoon-information {
  display: flex;
  justify-content: center;
  text-align: justify;
  margin-top: 3%;
}

.detailed-report-typhoon-information-chart {
  width: 50%;
  padding: 1%;
  text-align: center;
}

.detailed-report-typhoon-information-chart-not-available {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.detailed-report-typhoon-information-chart-not-available > img {
  width: 25%;
  height: auto;
  object-fit: contain;
  margin: 1% auto;
}

.detailed-report-rainfall-flood-column {
  height: 29.7cm;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.detailed-report-rainfall-flood-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.33, #003),
    color-stop(0.67, #002060)
  );
}

.detailed-report-rainfall-flood-title > img {
  width: 5%;
  object-fit: contain;
  margin: 1% 2%;
}

.detailed-report-rainfall-flood-title > h2 {
  margin-top: 0;
  color: #fff;
}

.detailed-report-rainfall-flood-map {
  margin: 5%;
}

.detailed-report-rainfall-flood-map > #floodmap-container {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.detailed-report-rainfall-flood-description {
  text-align: justify;
  margin-bottom: 2%;
}

.detailed-report-rainfall-flood-description
  .detailed-report-rainfall-flood-description-list {
  padding-left: 5%;
  list-style-type: disc;
}

.detailed-report-rainfall-flood-description
  .detailed-report-rainfall-flood-description-flood-hazard-information {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.detailed-report-rainfall-flood-information {
  display: flex;
  justify-content: center;
  text-align: justify;
  margin-top: 3%;
}

.detailed-report-rainfall-flood-information-chart {
  width: 50%;
  padding: 1%;
  text-align: center;
}

.detailed-report-rainfall-flood-information-chart-not-available {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.detailed-report-rainfall-flood-information-chart-not-available > img {
  width: 25%;
  height: auto;
  object-fit: contain;
  margin: 1% auto;
}

.detailed-report-extreme-heat-column {
  height: 29.7cm;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.detailed-report-extreme-heat-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.33, #003),
    color-stop(0.67, #002060)
  );
}

.detailed-report-extreme-heat-title > img {
  width: 5%;
  object-fit: contain;
  margin: 1% 2%;
}

.detailed-report-extreme-heat-title > h2 {
  margin-top: 0;
  color: #fff;
}

.detailed-report-extreme-heat-map {
  margin: 5%;
}

.detailed-report-extreme-heat-map > #extremeheatmap-container {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.detailed-report-extreme-heat-information {
  display: flex;
  justify-content: center;
  text-align: justify;
  margin-top: 3%;
}

.detailed-report-extreme-heat-information-chart {
  width: 50%;
  padding: 1%;
  text-align: center;
}

.detailed-report-extreme-heat-information-chart-not-available {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.detailed-report-extreme-heat-information-chart-not-available > img {
  width: 25%;
  height: auto;
  object-fit: contain;
  margin: 1% auto;
}

.detailed-report-drought-column {
  height: 29.7cm;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.detailed-report-drought-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.33, #003),
    color-stop(0.67, #002060)
  );
}

.detailed-report-drought-title > img {
  width: 5%;
  object-fit: contain;
  margin: 1% 2%;
}

.detailed-report-drought-title > h2 {
  margin-top: 0;
  color: #fff;
}

.detailed-report-drought-information {
  display: flex;
  justify-content: center;
  text-align: justify;
  margin-top: 3%;
}

.detailed-report-drought-information-chart {
  width: 50%;
  text-align: center;
}

.detailed-report-drought-information-chart-not-available {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.detailed-report-drought-information-chart-not-available > img {
  width: 25%;
  height: auto;
  object-fit: contain;
  margin: 1% auto;
}

#loading {
  background: url("../assets/logo.gif") no-repeat center center;
  position: absolute;
  top: 200;
  left: 0;
  height: 90%;
  width: 100%;
  z-index: 9999999;
  opacity: 0.5;
  margin-top: 50px;
}

#darkloading {
  background: url("../assets/logo_dark.gif") no-repeat center center;
  position: absolute;
  top: 200;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  opacity: 0.5;
  margin-top: 50px;
}
